// @ts-nocheck
import React, { forwardRef, useImperativeHandle, useCallback, useEffect, useMemo, useState } from 'react'
import { createStore } from 'polotno/model/store'
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno'
import { Toolbar } from 'polotno/toolbar/toolbar'
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons'
import { SidePanel, DEFAULT_SECTIONS } from 'polotno/side-panel'
import { Workspace } from 'polotno/canvas/workspace'

import blueprint from '../utils/blueprint'
import { initPolotnoUploader } from '../utils/uppy'
import { setTranslations } from 'polotno/config'

setTranslations({
  sidePanel: {
    createBody: "Add Small Text",
    createSubHeader: "Add Medium Text",
    createHeader: "Add Large Text",
  },
})

const PageControls = () => {
  return <></>
}

export type ImageEditorState = {
  thumbnail: string
  production: string
  design: any
}

export type ImageEditorRef = {
  getState: () => Promise<ImageEditorState>,
  doContentShift: (toRight: boolean) => void,
}

type ImageEditorProps = {
  design: any
  onChange: (design: any) => void
  frontAndBack: boolean
  onLoaded: (frontAndBack: boolean) => void
}

const ImageEditor = forwardRef(({ design, onChange, frontAndBack, onLoaded }: ImageEditorProps, ref) => {
  // Our books are 17.5cm, which is about 2000 pixels at 300DPI
  // 1080 is a nice size as there are many templates, but we
  // will export with pixelRatio 2+
  const faceWidth = 17.5
  const faceHeight = 17.5
  const ppcm = 1080 / faceWidth
  const spineWidth = 1.65
  const printingUncertainty = 0.5 * ppcm

  const [store, setStore] = useState<any>(undefined)

  useEffect(() => {
    const _store = createStore({
      key: 'HTPTBkg0itBALDhLpD61',
      showCredit: false,
    })

    _store.setScale(0.5)
    _store.addPage()

    // .5cm of the 17.5cm size-with-bleed
    // _store.activePage?.set({ bleed: printingUncertainty })
    // Disabled per @AshleyKenny
    // _store.toggleBleed(true)

    setStore(_store)
  }, [])

  useMemo(() => {
   // w/o timeout, change event is fired on init
    window.setTimeout(() => store?.on('change', onChange))
  }, [onChange, store])

  useMemo(() => {
    store?.setSize(frontAndBack ? (2 * faceWidth * ppcm + spineWidth * ppcm) : faceWidth * ppcm, faceHeight * ppcm)
  }, [store, faceWidth, ppcm, spineWidth, faceHeight, frontAndBack])

  const [lastFAB, setLastFAB] = useState<boolean>(frontAndBack)
  useMemo(() => {
    store?.deleteElements([
      'spine-bar',
      'spine-text-0',
      'spine-text-1',
      'spine-text-2',
    ])

    if (store?.activePage) {
      if (frontAndBack) {
        store?.activePage.addElement({
          id: 'spine-bar',
          type: 'figure',
          fill: 'gray',
          stroke: 'black',
          strokeWidth: printingUncertainty,
          width: ppcm * spineWidth + printingUncertainty * 2,
          height: faceHeight * ppcm + 2 * printingUncertainty,
          x: faceWidth * ppcm - printingUncertainty,
          y: -printingUncertainty,
          opacity: 0.2,

          selectable: false,
          showInExport: false,
          alwaysOnTop: true,
        })

        const text = [
          {
            x: 0,
            text: 'Back',
          },
          {
            width: spineWidth * ppcm + 200,
            x: faceWidth * ppcm - 200 / 2,
            text: 'Spine',
          },
          {
            x: faceWidth * ppcm + spineWidth * ppcm,
            text: 'Front',
          },
        ]

        text.forEach((vals, i) => {
          store?.activePage.addElement(Object.assign({
            id: `spine-text-${ i }`,
            type: 'text',
            x: 0,
            y: faceHeight * ppcm + 120,
            width: faceWidth * ppcm,
            text: 'Front',
            fontSize: 80,
            fontFamily: 'Roboto',
            align: 'center',
            stroke: 'black',

            selectable: false,
            showInExport: false,
            alwaysOnTop: true,
          }, vals))
        })
      }
    }
  }, [store?.activePage, ppcm, spineWidth, faceHeight, faceWidth, printingUncertainty, frontAndBack])

  useEffect(() => {
    if (design) {
      store?.loadJSON(design)

      onLoaded(store?.activePage.computedWidth > 1080)
    }
  }, [!!store, design])

  useEffect(initPolotnoUploader, [])

  const exportState = useCallback(async () => {
    store.setElementsPixelRatio(2)

    return {
      thumbnail: await store.toDataURL({ pixelRatio: 0.25, /*includeBleed: true,*/ mimeType: 'image/jpeg' }),
      production: await store.toDataURL({ pixelRatio: 2, /*includeBleed: true*/ }),
      design: JSON.stringify(store.toJSON()),
    }
  }, [store])

  useImperativeHandle(ref, () => ({
    async getState(): Promise<ImageEditorState> {
      return exportState()
    },
    // When the customer toggles that they want a full cover (or don't), we can shift
    // the content over.
    doContentShift(toRight: boolean): void {
      const elements = store?.activePage?.children
      if (!elements) return

      // Shift over contents when people toggle between full covers and just front covers

      elements.forEach((el) => {
        if (toRight) {
          // This is now a full cover, anything on the original cover needs to be shifted over to
          // where the front part will now be.
          if (el.x < faceWidth * ppcm) {
            el.set({ x: el.x + faceWidth * ppcm + spineWidth * ppcm })
          }
        } else {
            el.set({ x: el.x - faceWidth * ppcm - spineWidth * ppcm })
          /*if (el.x > faceWidth * ppcm) {
            // This is now a front cover only, anything which would be where the front appears on a full
            // cover needs to be shifted back.
            el.set({ x: el.x - faceWidth * ppcm - spineWidth * ppcm })
          } else {
            // and anything which was on the former back part of the cover needs to be removed.
            // Note that this doesn't take into account the element's width, an element stretched
            // from the back cover to the front will be removed.
            store.deleteElements([el.id])
          }*/
        }
      })
    },
  }))

  let sections: typeof DEFAULT_SECTIONS = []
  for (let i=0; i < DEFAULT_SECTIONS.length; i++){
    // We don't really want to let people resize the image
    if (DEFAULT_SECTIONS[i].name !== 'size'){
      sections.push(DEFAULT_SECTIONS[i])
    }
  }

  return <div className="bp4">
      <style scoped>
        { blueprint }
      </style>
      <PolotnoContainer style={{ width: Math.min(879, window.innerWidth) + 'px', height: '800px' }}>
        { !!store && <>
          <SidePanelWrap>
            {/* TODO: default section of background disappears when navigating from front cover to back cover */}
            <SidePanel store={ store } sections={ sections } defaultSection="background" />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar store={store} />
            <Workspace store={store} components={{ PageControls }} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </>
        }
      </PolotnoContainer>
    </div>
})

export default ImageEditor
